<template>
  <div class="settlementFinanceRider">
    <div class="search-box flexbox">
      <div class="search flexbox flex-align-center">
        <el-input
          v-model="form.riderNameOrPhone"
          placeholder="骑手姓名/账号"
          clearable
          style="width: 160px"
        ></el-input>
        <el-date-picker
          v-model="time"
          class="ml10"
          clearable
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="提现开始时间"
          end-placeholder="提现结束时间"
        >
        </el-date-picker>
        <el-select
          v-model="form.agentSid"
          filterable
          clearable
          remote
          placeholder="归属代理"
          class="ml10"
          :remote-method="searchAgent"
          :loading="loading">
          <el-option
            v-for="item in agentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="form.state" clearable class="ml10 mr10" placeholder="审核状态">
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
      <div class="data">
        <div class="date">{{day}}-{{day_30}}</div>
        <div class="flexbox flex-justify-between flex-align-center">
          <div class="data-item">
            <p class="num">{{withdrawData.applyCount || 0}}</p>
            <p>申请提现笔数</p>
          </div>
          <div class="data-item">
            <p class="num">￥{{withdrawData.applyWithdrawsSum || 0}}</p>
            <p>申请提现金额</p>
          </div>
          <div class="data-item">
            <p class="num">￥{{withdrawData.passWithdrawsSum || 0}}</p>
            <p>审核通过金额</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="table-box">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="createTime" align="center" label="申请时间">
          <template slot-scope="scope">
            <span>{{scope.row.createTime | timeFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="riderName" align="center" label="骑手姓名"></el-table-column>
        <el-table-column prop="riderPhone" align="center" label="骑手账号"></el-table-column>
        <el-table-column prop="agentName" align="center" label="归属代理"></el-table-column>
        <el-table-column prop="applyWithdraws" align="center" label="提现金额"></el-table-column>
        <el-table-column prop="serviceCharge" align="center" label="提现手续费"></el-table-column>
        <el-table-column prop="name" align="center" label="实际到账">
          <template slot-scope="scope">
            <span>{{(scope.row.applyWithdraws - scope.row.serviceCharge).toFixed(2) * 1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="withdraws" align="center" label="已提现金额"></el-table-column>
        <el-table-column prop="accounts" align="center" label="收款卡号"></el-table-column>
        <el-table-column prop="state" align="center" label="审核状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state === 1">待审核</span>
            <span v-else-if="scope.row.state === 2">交易成功</span>
            <span v-else-if="scope.row.state === 3">审核失败</span>
            <span v-else-if="scope.row.state === 4">审核成功-出款中</span>
            <span v-else-if="scope.row.state === 5">审核成功-出款失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="errMsg" align="center" label="备注" width="380px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.state === 1" @click="openWithDrawDialog(scope.row)">审核</el-button>
            <el-button type="text" v-else @click="openWithDrawDialog(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 翻页 -->
    <div class="totalPage">  
      <el-pagination class="mt10" background :page-count="totalPage" 
        :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
    </div>
    <!-- 提现弹窗 -->
    <el-dialog
      :title="state === 1 ? '审核' : '详情'"
      :visible.sync="withDrawDialog"
      :close-on-click-modal="false"   
      class="withDrawDialog"
      width="1196px">
      <p class="title">基本信息</p>
      <el-table :data="basicAndAccountInfo" border style="width: 100%">
        <el-table-column prop="riderCreateDate" align="center" label="创建时间">
          <template slot-scope="scope">
            <span>{{scope.row.riderCreateDate | timeFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="agentName" align="center" label="归属代理"></el-table-column>
        <el-table-column prop="dayAvgProfit" align="center" label="日均收益"></el-table-column>
        <el-table-column prop="weekShopCount" align="center" label="近7天服务商家数"></el-table-column>
        <el-table-column prop="lastWithdrawsDate" align="center" label="上次提现时间"></el-table-column>
        <el-table-column prop="totalOrderCount" align="center" label="累计接单数"></el-table-column>
        <el-table-column prop="cancelOrderCount" align="center" label="累计拒单数"></el-table-column>
      </el-table>
      <p class="title">账户信息</p>   
      <el-table :data="basicAndAccountInfo" border style="width: 100%">
        <el-table-column prop="profitPrice" align="center" label="总收益"></el-table-column>
        <el-table-column prop="withdraws" align="center" label="已提现"></el-table-column>
        <el-table-column prop="canWithdraws" align="center" label="可提现"></el-table-column>
        <el-table-column prop="name" align="center" label="总收益≥可提现+已提现">
          <template slot-scope="scope">
            <span v-if="scope.row.profitPrice >= scope.row.canWithdraws + scope.row.withdraws">是</span>
            <span v-else style="color:red">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="toVerifyCount" align="center" label="待审核笔数"></el-table-column>
        <el-table-column prop="toVerifyMoney" align="center" label="待审核金额"></el-table-column>
      </el-table>
      <p class="title">本次提现信息</p>   
      <el-table :data="thisWithdraw" border style="width: 100%">
        <el-table-column prop="createTime" align="center" label="申请时间">
          <template slot-scope="scope">
            <span>{{scope.row.createTime | timeFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyWithdraws" align="center" label="本次提现金额(元)"></el-table-column>
        <el-table-column prop="serviceCharge" align="center" label="本次提现手续费(元)"></el-table-column>
        <el-table-column prop="trueName" align="center" label="持卡人姓名"></el-table-column>
        <el-table-column prop="bankName" align="center" label="开户行"></el-table-column>
        <el-table-column prop="accounts" align="center" label="收款卡号"></el-table-column>
        <el-table-column prop="platformUserName" align="center" label="审核人"></el-table-column>
        <el-table-column prop="updateTime" align="center" label="审核时间">
          <template slot-scope="scope">
            <span v-if="scope.row.state !== 1">{{ scope.row.updateTime | timeFormat}}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer" v-if="state === 1">
        <el-button @click="rejectDialog = true">驳回</el-button>
        <el-button type="primary" @click="adopt">通过</el-button>
      </span>
    </el-dialog>
    <!-- 驳回弹窗 -->
    <el-dialog
      title="备注"
      :visible.sync="rejectDialog"
      :close-on-click-modal="false"
      width="480px">
      <el-input v-model="errMsg" type="textarea" show-word-limit maxlength="30" :rows="8" placeholder="请输入驳回理由~"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialog = false">取 消</el-button>
        <el-button type="primary" @click="reject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sg_riderWithdraw, $sg_riderCountInfo, $sg_riderqueryRiderDetail, $sg_queryWithdrawsDetail, $sg_riderVerify, 
  $sg_agentSearching,
} from '@/api/settlementFinanceRider';

export default {
  data() {
    return {
      day: '',
      day_30: '',
      form: {
        state: '',
        startDate: '',
        endDate: '',
        riderNameOrPhone: '',
        agentSid: '',
      },
      time: '',
      loading: false,
      agentName: '',
      agentOptions: [],
      stateOptions: [
        {
          value: 1,
          label: '待审核',
        },
        {
          value: 2,
          label: '交易成功',
        },
        {
          value: 3,
          label: '审核失败',
        },
        {
          value: 4,
          label: '审核成功-出款中',
        },
        {
          value: 5,
          label: '审核成功-出款失败',
        },
      ],
      withdrawData: {},
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      withDrawDialog: false,
      state: '',
      basicAndAccountInfo: [],
      thisWithdraw: [],
      rejectDialog: false,
      errMsg: '',
    };
  },
  filters: {
    // 2021-07-07T15:16:35(东八区时间) 转换为 yyyy-MM-dd HH:mm:ss
    timeFormat(val) {
      if (!val) return '';
      const time = new Date(val);
      const y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let min = time.getMinutes();
      m = m < 10 ? `0${m}` : m;
      d = d < 10 ? `0${d}` : d;
      h = h < 10 ? `0${h}` : h;
      min = min < 10 ? `0${min}` : min;
      return `${y}-${m}-${d} ${h}:${min}`;
    },
  },
  created() {
    this.getTime();
    this.search();
    this.riderCountInfo();
  },
  methods: {
    // 骑手提现列表
    search() {
      this.currentPage = 1;
      this.riderWithdraw();
    },
    // 请求骑手提现列表
    riderWithdraw() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 10,
        ...this.form,
      };
      if (this.time && this.time.length > 0) {
        params.startDate = this.time[0];
        params.endDate = this.time[1];
      }
      $sg_riderWithdraw(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 查看当前月的骑手提现统计信息
    riderCountInfo() {
      $sg_riderCountInfo().then((res) => {
        this.withdrawData = res;
      });
    },
    // 搜索代理商
    searchAgent(agentName) {
      this.loading = true;
      const params = {
        agentName,
      };
      $sg_agentSearching(params).then((res) => {
        this.loading = false;
        const options = [];
        res.forEach((val) => {
          const json = {
            value: val.agentSid,
            label: `${val.agentName}/${val.phone}`,
          };
          options.push(json);
        });
        this.agentOptions = options;
      });
    },
    // 打开审核/详情弹窗
    openWithDrawDialog(row) {
      this.state = row.state;
      this.errMsg = '';
      this.thisWithdraw = [];
      this.basicAndAccountInfo = [];
      this.withDrawDialog = true;
      const params = {
        riderId: row.riderId,
      };
      // 查询骑手提现信息和骑手基本信息
      $sg_riderqueryRiderDetail(params).then((res) => {
        this.basicAndAccountInfo.push(res);
      });
      const params2 = {
        riderApplyId: row.id,
      };
      // 查询提现详情
      $sg_queryWithdrawsDetail(params2).then((res) => {
        this.thisWithdraw.push(res);
      });
    },
    // 驳回
    reject() {
      this.riderVerify(2);
    },
    // 通过
    adopt() {
      this.$confirm('审核通过后系统将打款至分骑手收款账号，确认通过吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
      }).then(() => {
        this.riderVerify(1);
      }).catch(() => {});
    },
    // 审核接口
    riderVerify(state) {
      const thisWithdraw = this.thisWithdraw[0];
      const params = {
        riderApplyId: thisWithdraw.id,
        state,
        errMsg: this.errMsg,
      };
      $sg_riderVerify(params).then(() => {
        this.rejectDialog = false;
        this.withDrawDialog = false;
        this.riderWithdraw();
      });
    },
    // 翻页
    pageChange() {
      this.riderWithdraw();
    },
    // 获取当前时间和30天前时间
    getTime() {
      const day = new Date(new Date().getTime());
      const month = day.getMonth() + 1;
      const date = day.getDate();
      const day_30 = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
      const month_30 = day_30.getMonth() + 1;
      const date_30 = day_30.getDate();
      this.day = `${month}月${date}日`;
      this.day_30 = `${month_30}月${date_30}日`;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";
.settlementFinanceRider {
  width: 100%;
  .search {
    background-color: #fff;
    padding: 10px;
  }
  .data {
    width: 380px;
    background-color: #fff;
    padding: 10px;
    margin-left: 10px;
    .date {
      width: 100%;
      text-align: center;
    }
    .data-item {
      width: 30%;
      margin-top: 10px;
      p {
        width: 100%;
        text-align: center;
        color: #444;
        font-size: 15px;
      }
      .num {
        font-weight: bold;
        color: $--theme-color;
      }
    }
  }
  .table-box{
    margin-top: 20px;
    background-color: #fff;
    padding: 10px;
    min-height: 580px;
  }
  .totalPage{
    width: 100%;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
  }
  .withDrawDialog {
    .el-dialog__body{
      padding-top: 0px;
    }
    .el-dialog__footer{
      text-align: center;
      .el-button{
        width: 128px;
        height: 32px;
      }
      .el-button--default{
        background-color: #4D4D4D;
        color: #FFFFFF;
        border: none;
      }
    }
    .title{
      font-size: 14px;
      color: #666666;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    th {
      background-color: #ECECEC;
      color: #4D4D4D;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
