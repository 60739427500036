import api from './api';

// 查询骑手提现申请
export const $sg_riderWithdraw = (params) => api.get('/j/platform/withdraw/rider/page', params);

// 查看当前月的骑手提现统计信息
export const $sg_riderCountInfo = (params) => api.get('/j/platform/withdraw/rider/countInfo', params);

// 按代理商名称模糊搜索代理列表
export const $sg_agentSearching = (params) => api.get('/j/platform/withdraw/rider/searching', params);

// 查询骑手提现信息和骑手基本信息
export const $sg_riderqueryRiderDetail = (params) => api.get('/j/platform/withdraw/rider/queryRiderDetail', params);

// 查询骑手提现信息和骑手基本信息
export const $sg_queryWithdrawsDetail = (params) => api.get('/j/platform/withdraw/rider/queryWithdrawsDetail', params);

// 审核骑手提现申请
export const $sg_riderVerify = (params) => api.post('/j/platform/withdraw/rider/verify', params);

/**
* http://192.168.2.224:9800/
*/
